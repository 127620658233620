import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef } from '@angular/core';
import { GhostOverlayModel } from '../../ghost-overlay/model/ghost-overlay.model';
import { GhostOverlayModule } from '../../ghost-overlay/ghost-overlay.module';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { InfoPanelComponent } from '../info-panel/info-panel.component';
import { ComponentState, COMPONENT_STATE } from '@futura/futura-ui/ghost';

@Component({
  selector: 'app-fut-card',
  templateUrl: './fut-card.component.html',
  styleUrls: ['./fut-card.component.scss'],
  standalone: true,
  imports: [GhostOverlayModule, NgClass, MatIcon, NgTemplateOutlet, TranslocoModule, InfoPanelComponent],
})
export class FutCardComponent implements OnChanges, OnInit {
  @Input() public cardTitle?: string | TemplateRef<unknown>;

  public cardTitleString?: string;
  public cardTitleTemplate?: TemplateRef<unknown>;

  @Input() public state: ComponentState = COMPONENT_STATE.NONE;
  @Input() public ghostConfig: Partial<GhostOverlayModel> = {};
  @Output() public readonly ghostClick = new EventEmitter<void>();

  @Input() public icon?: string;
  @Input() public fontSet = 'material-icons-outlined';
  @Input() public infoTitle?: string;
  @Input() public infoTemplate?: TemplateRef<unknown>;
  @Output() public iconClick = new EventEmitter<void>();

  @Input() public pushToBottom?: boolean;
  @Input() public type: 'primary-gradient' | 'quaternary' | 'white' = 'quaternary';

  @Input() public userLabels?: Array<string>;
  @Input() public labels?: { lables: Array<string>; whitelist: boolean };
  @Input() public locked?: boolean;
  @Input() public lockConfig: Partial<GhostOverlayModel> = {};
  @Output() public readonly lockClick = new EventEmitter<void>();

  public ngOnInit(): void {
    this.setCardTitleTyper();
  }

  public ngOnChanges(): void {
    this.ngOnInit();
  }

  private isTemplate(title: string | TemplateRef<unknown>): title is TemplateRef<unknown> {
    return title instanceof TemplateRef;
  }

  private setCardTitleTyper() {
    if (!this.cardTitle) return;

    if (this.isTemplate(this.cardTitle)) this.cardTitleTemplate = this.cardTitle;
    else this.cardTitleString = this.cardTitle;
  }
}
