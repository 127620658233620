<app-base-dialog dialogTitle="streak.dialog.title">
  <span>{{ 'streak.dialog.subtitle' | transloco }}</span>
  <div class="mt-4">
    <app-streak-display [autoLoad]="false" [justify]="'around'" [streakInfo]="streakInfo"></app-streak-display>
  </div>
  @if (streakState === 'on_streak') {
    <div class="mt-4">
      <app-fut-card [type]="'quaternary'" [style.--min-height]="'auto'">
        <b>{{ 'streak.on_streak.phrases.title' | transloco }}</b>
        <div>{{ streakPhrase | transloco }}</div>
      </app-fut-card>
    </div>
  }
  @if (!streakState || streakState === 'not_on_streak') {
    <div class="mt-4">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <h3 class="fut-h3 m-0">
          @if (state && state !== 'NONE') {
            <fut-ghost-fragment [state]="state" [text]="'streak.dialog.progress_title'"></fut-ghost-fragment>
          }
          @if (!state || state === 'NONE') {
            <span>{{ 'streak.dialog.progress_title' | transloco }}</span>
          }
        </h3>
        @if (!state || state === 'NONE') {
          <div class="d-flex justify-content-end align-items-baseline">
            <h3 class="fut-h3 m-0">{{ currentStreak }}</h3>
            <small class="text-muted">/{{ maxStreak }}</small>
          </div>
        }
        @if (state && state !== 'NONE') {
          <fut-ghost-fragment [state]="state" [text]="'0/0'"></fut-ghost-fragment>
        }
      </div>
      <app-progress-bar-chart
        [state]="state"
        [total]="maxStreak"
        [hideTooltip]="true"
        [value]="currentStreak"
        [color]="'var(--fut-secondary-800)'"
        [style.--bar-height.em]="1"></app-progress-bar-chart>
    </div>
  }
  @if (!hasTestToday || streakState) {
    <div class="mt-4">
      @if (!hasTestToday && state === 'NONE') {
        <span>{{ 'streak.dialog.no_simulation_today' | transloco }}</span>
      }
      @if (state && state !== 'NONE') {
        <fut-ghost-fragment [state]="state" [text]="'streak.dialog.no_simulation_today'"></fut-ghost-fragment>
      }
    </div>
  }
  <div class="w-100 mt-4">
    <button (click)="goSimulator()" [disabled]="state && state !== 'NONE'" class="fut-btn fut-btn-accent w-100 h-100">
      @if (state && state !== 'NONE') {
        <fut-ghost-fragment [state]="state" [text]="'streak.dialog.cta.continue_streak'"></fut-ghost-fragment>
      }
      @if (!state || state === 'NONE') {
        <span>
          {{ currentStreak === 0 ? ('streak.dialog.cta.start_streak' | transloco) : ('streak.dialog.cta.continue_streak' | transloco) }}
        </span>
      }
    </button>
  </div>
</app-base-dialog>
